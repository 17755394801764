<template>
  <div class="test1">
    <div>
      <h2>Text</h2>
      <input-text v-model="inputText0"/>

      <h2>Text <small>(Icon left, large size)</small></h2>
      <input-text v-model="inputText1"/>
    </div>

    <div>
      <h2>Password</h2>
      <input-password v-model="inputPassword"/>
    </div>

    <div>
      <h2>Number</h2>
      <input-number v-model="inputNumber0"/>
    </div>

    <div>
      <h2>Select <small>(Single, Text)</small></h2>
      <input-select v-model="inputSelect0"/>

      <h2>Select <small>(Multiple, Text)</small></h2>
      <input-select v-model="inputSelect1"/>

      <h2>Select <small>(Multiple, Text, Filter)</small></h2>
      <input-select v-model="inputSelect5"/>

      <h2>Select <small>(Single, Tag)</small></h2>
      <input-select v-model="inputSelect2"/>

      <h2>Select <small>(Multiple, Tag)</small></h2>
      <input-select v-model="inputSelect3"/>

      <h2>Select <small>(Multiple, Tag, Filter)</small></h2>
      <input-select v-model="inputSelect4"/>
    </div>

    <div>
      <h2>DatePicker <small>Multiple</small></h2>
      <input-date-picker v-model="inputDate0"/>

      <h2>DatePicker <small>Multiple</small></h2>
      <input-date-picker v-model="inputDate1"/>
    </div>

    <div>
      <h2>Link</h2>
      <input-link v-model="inputLink0"/>
    </div>

  </div>
</template>

<script>
import InputText from '@/components/inputs/InputText'
import InputNumber from '@/components/inputs/InputNumber'
import InputPassword from '@/components/inputs/InputPassword'
import InputSelect from '@/components/inputs/InputSelect'
import InputDatePicker from '@/components/inputs/InputDatePicker'
import InputLink from '@/components/inputs/InputLink'
import { Search } from '@/utils/icons'

export default {
  name: 'Controls',
    components: { InputText, InputPassword, InputSelect, InputNumber, InputDatePicker, InputLink},

  created() {
    console.log('env.VUE_APP_API_URL', process.env.VUE_APP_API_URL)
    console.log('env.VUE_APP_API_VERSION', process.env.VUE_APP_API_VERSION)
  },
  data() {
    return {
        inputText0: {
            placeholder: 'email@mail.com',
            value: null,
            isValid: true,
            clearable: true,
            required: true,
            readonly: false,
            maxlength: 5,
            minlength: 1,
            errorKey: '' // Ключ по которому не сработала валидация
        },

        inputText1: {
          placeholder: 'Search...',
          value: null,
          isValid: true,
          clearable: false,
          required: false,
          readonly: false,
          //maxlength: 5,
          //minlength: 1,
          class: 'lg',
          leftIcon: Search
        },

        inputPassword: {
            placeholder: 'email@mail.com',
            value: null,
            isValid: true,
            clearable: false,
            required: true,
            readonly: false,
            maxlength: 5,
            minlength: 1
        },

        inputNumber0: {
          placeholder: 'Number',
          value: null,
          isValid: true,
          clearable: true,
          required: true,
          readonly: false,
          maxlength: 5,
          minlength: -1
        },

        inputSelect0: {
          placeholder: 'Input select',
          value: [], // Выбранные значения. Если ничего не выбрано передавать пустой массив
          isValid: true, // Валидно ли поле
          clearable: true, // Показывает значок очистки
          required: true, // Обязательность
          readonly: false, // Редактируемость
          isMultiselect: false, // Возможность множественного выбора 
          isTags: false, // Показывать в виде тегов
          isLiveSearch: false, // Показывать возможность фильтрации по строке
          dataList: [ // Данные для выпадающего списка
            { id: 1, name: 'id 1 text text text' },
            { id: 2, name: 'id 2 text' },
            { id: 3, name: 'id 3 text text' },
            { id: 4, name: 'id 4 text text' },
            { id: 5, name: 'id 5 text' },
            { id: 6, name: 'id 6 text' },
          ]
        },

        inputSelect1: {
          placeholder: 'Input select',
          value: [], // Выбранные значения. Если ничего не выбрано передавать пустой массив
          isValid: true, // Валидно ли поле
          clearable: true, // Показывает значок очистки
          required: true, // Обязательность
          readonly: false, // Редактируемость
          isMultiselect: true, // Возможность множественного выбора 
          isTags: false, // Показывать в виде тегов
          isLiveSearch: false, // Показывать возможность фильтрации по строке
          dataList: [ // Данные для выпадающего списка
            { id: 1, name: 'id 1 text text text' },
            { id: 2, name: 'id 2 text' },
            { id: 3, name: 'id 3 text text' },
            { id: 4, name: 'id 4 text text' },
            { id: 5, name: 'id 5 text' },
            { id: 6, name: 'id 6 text' },
          ]
        },

        inputSelect2: {
          placeholder: 'Input select',
          value: [], // Выбранные значения. Если ничего не выбрано передавать пустой массив
          isValid: true, // Валидно ли поле
          clearable: true, // Показывает значок очистки
          required: true, // Обязательность
          readonly: false, // Редактируемость
          isMultiselect: false, // Возможность множественного выбора 
          isTags: true, // Показывать в виде тегов
          isLiveSearch: false, // Показывать возможность фильтрации по строке
          dataList: [ // Данные для выпадающего списка
            { id: 1, name: 'id 1 text text text' },
            { id: 2, name: 'id 2 text' },
            { id: 3, name: 'id 3 text text' },
            { id: 4, name: 'id 4 text text' },
            { id: 5, name: 'id 5 text' },
            { id: 6, name: 'id 6 text' },
          ]
        },

        inputSelect3: {
          placeholder: 'Input select',
          value: [], // Выбранные значения. Если ничего не выбрано передавать пустой массив
          isValid: true, // Валидно ли поле
          clearable: true, // Показывает значок очистки
          required: true, // Обязательность
          readonly: false, // Редактируемость
          isMultiselect: true, // Возможность множественного выбора 
          isTags: true, // Показывать в виде тегов
          isLiveSearch: false, // Показывать возможность фильтрации по строке
          dataList: [ // Данные для выпадающего списка
            { id: 1, name: 'id 1 text text text' },
            { id: 2, name: 'id 2 text' },
            { id: 3, name: 'id 3 text text' },
            { id: 4, name: 'id 4 text text' },
            { id: 5, name: 'id 5 text' },
            { id: 6, name: 'id 6 text' },
          ]
        },

        inputSelect4: {
          placeholder: 'Input select',
          value: [], // Выбранные значения. Если ничего не выбрано передавать пустой массив
          isValid: true, // Валидно ли поле
          clearable: true, // Показывает значок очистки
          required: true, // Обязательность
          readonly: false, // Редактируемость
          isMultiselect: true, // Возможность множественного выбора 
          isTags: true, // Показывать в виде тегов
          isLiveSearch: true, // Показывать возможность фильтрации по строке
          dataList: [ // Данные для выпадающего списка
            { id: 1, name: 'id 1 text text text' },
            { id: 2, name: 'id 2 text' },
            { id: 3, name: 'id 3 text text' },
            { id: 4, name: 'id 4 text text' },
            { id: 5, name: 'id 5 text' },
            { id: 6, name: 'id 6 text' },
          ]
        },

        inputSelect5: {
          placeholder: 'Input select',
          value: [], // Выбранные значения. Если ничего не выбрано передавать пустой массив
          isValid: true, // Валидно ли поле
          clearable: true, // Показывает значок очистки
          required: true, // Обязательность
          readonly: false, // Редактируемость
          isMultiselect: true, // Возможность множественного выбора 
          isTags: false, // Показывать в виде тегов
          isLiveSearch: true, // Показывать возможность фильтрации по строке
          dataList: [ // Данные для выпадающего списка
            { id: 1, name: 'id 1 text text text' },
            { id: 2, name: 'id 2 text' },
            { id: 3, name: 'id 3 text text' },
            { id: 4, name: 'id 4 text text' },
            { id: 5, name: 'id 5 text' },
            { id: 6, name: 'id 6 text' },
          ]
        },

        inputDate0: {
          placeholder: 'Select Date',
          value: '2022-07-15T13:40:26.743Z',
          isValid: true,
          clearable: true,
          required: true,
          readonly: false,
          isMultiselect: false
          //maxlength: 5,
          //minlength: -1
        },

        inputDate1: {
          placeholder: 'Select Date',
          value: ['2022-07-01T13:40:26.743Z', '2022-07-15T13:40:26.743Z'],
          isValid: true,
          clearable: true,
          required: false,
          readonly: false,
          isMultiselect: true
          //maxlength: 5,
          //minlength: -1
        },

        inputLink0: {
          placeholder: 'Type text',
          value: 'https://google.com',
          isValid: true,
          clearable: true,
          required: false,
          readonly: false,
          //maxlength: 5,
          //minlength: -1
        },
    }
  }

}
</script>

<style lang="scss">
  .test1 {
    h2 {
      margin-top: 25px;
      font-size: 20px;
      text-align: center;
      padding: 15px;
    }
    display: flex;
    > div {
      padding: 10px;
      width: 300px;
    }
  }
</style>
