<template>
  <div class="main-input password" :class="{error: !inputModel.isValid}">
    <input 
      :type="!isText ? 'password' : 'text'" 
      :placeholder="inputModel.placeholder" 
      :readonly="inputModel.readonly" 
      :minlength="inputModel.minlength" 
      :maxlength="inputModel.maxlength" 
      v-model="inputModel.value"/>
     <!--
       <div class="error-icon" v-if="!inputModel.isValid" v-html="InfoCircle"></div>
     --> 
      <div class="eye" v-if="inputModel.value" @click="isText = !isText" v-html="eyeIcon"></div>

    </div>   
</template>

<script>
//<div class="clearable" v-if="inputModel.isValid && !inputModel.readonly && inputModel.clearable && inputModel.value" @click="inputModel.value = null" v-html="Cross"></div>
import { inputMixin } from '@/utils/input-mixin'
import { Eye, EyeCross } from '@/utils/icons'

export default {
  name: 'InputPassword',
  mixins: [inputMixin],

  data() {
    return {
      Eye, EyeCross,
      isText: false
    }
  },

  computed: {
    eyeIcon() {
      return this.isText ? this.EyeCross : this.Eye
    }
  },
  
  methods: {
      validate() {
          if(this.inputModel.required && !this.inputModel.value) {
            this.inputModel.isValid = false
            return
          }


          if(this.inputModel.maxlength && this.inputModel.value.length > this.inputModel.maxlength) {
            this.inputModel.isValid = false
            return
          }

          if(this.inputModel.minlength && this.inputModel.value.length < this.inputModel.minlength) {
            this.inputModel.isValid = false
            return
          }

          this.inputModel.isValid = true
      }
  },
}
</script>
